<template>
  <v-card>
    <v-card-title>
      Sensors
    </v-card-title>
    <v-data-table
      v-if="sensors.length"
      :headers="headers"
      :items="sensors"
      :items-per-page="50"
      item-key="id"
      class="elevation-1"
      sort-by="name"
    >
      <template v-slot:body="{items}">
        <tbody>
            <template v-for="(item,i) in items">
              <tr @click="routeTo(item)" :key="item.id">
                <td>
                  <v-icon medium :color="item.online ? 'green' : 'red'">mdi-checkbox-blank-circle</v-icon>
                </td>
                <td>
                  <v-text-field
                    hide-details
                    single-line
                    :color="item.success ? 'green' : '#012b45'"
                    @click.stop="setItem(item)"
                    @change="editItem(item, 'name', i, $event)"
                    v-model="item.name"
                  ></v-text-field>
                </td>
                <td v-if="item.low !== null" class="hidden-xs-only">
                  <v-text-field
                    hide-details
                    single-line
                    @click.stop="setItem(item)"
                    @change="editItem(item, 'low', i, $event)"
                    type="number"
                    :color="item.success ? 'green' : '#012b45'"
                    v-model="item.low"
                  ></v-text-field>
                </td>
                <td v-else @click.stop="item.low = 30" class="hidden-xs-only">Not Set</td>
                <td v-if="item.high !== null" class="hidden-xs-only">
                  <v-text-field
                    hide-details
                    single-line
                    @click.stop="setItem(item)"
                    @change="editItem(item, 'high', i, $event)"
                    type="number"
                    :color="item.success ? 'green' : '#012b45'"
                    v-model="item.high"
                  ></v-text-field>
                </td>
                <td v-else @click.stop="item.high = 44" class="hidden-xs-only">Not Set</td>
                <template v-if="item.lastCheckIn">
                  <td class="hidden-xs-only">{{ lastUpdated(item.lastCheckIn) }}</td>
                  <td>
                    <v-chip
                      :color="checkSensorRange(item.currentTemp, item.low, item.high)"
                      label
                      dark
                    >{{ item.currentTemp }}</v-chip>
                  </td>
                </template>
                <template v-else>
                  <td class="text-left" colspan="2">No Readings</td>
                </template>
              </tr>
            </template>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "sensors",
  props: ["sensors"],
  data() {
    return {
      headers: [
        {
          text: "Online",
          value: "online",
          width: "8%",
          sortable: false,
          class: "th-header",
        },
        {
          text: "Name",
          value: "name",
          align: "start",
        },
        {
          text: "Alarm Low (℉)",
          value: "low",
          width: "10%",
          class: "hidden-xs-only",
          sortable: false,
        },
        {
          text: "Alarm High (℉)",
          value: "high",
          width: "10%",
          class: "hidden-xs-only",
          sortable: false,
        },
        {
          text: "Last Update",
          value: "lastCheckIn",
          width: "20%",
          class: "hidden-xs-only",
        },
        { text: "Temp (℉)", value: "currentTemp", width: "10%" },
      ],
    };
  },
  async created() {
  },
  computed: {
    ...mapState("location", []),
    ...mapGetters("user", [])
  },
  methods: {
    setItem(item) {
      this.currentItem = { ...item };
    },
    async editItem(item, prop, i, event) {
      if (this.isMDX || this.isKDX || this.isTDX) return;
      let result;
      if (item.device.model === "mdx") {
        result = await this.$store.dispatch("sensor/updateSensorProperty", {
          id: item.id,
          prop,
          value: event,
        });
      } else {
        result = await this.$store.dispatch("sensor/updateSensorProperty", {
          id: item.id,
          prop,
          value: event,
          duration: item.duration ? item.duration : "30",
          durationChange: prop === "duration" ? true : false,
        });
      }

      if (result) {
        // item.success = true;
        // this.items[i].success = true;
        // item.success = true;
        // setTimeout(() => {
        //   item.success = false;
        // }, 2000);
      } else {
        console.log("error");
      }
    },
    lastUpdated(date) {
      let fullDate = new Date(date).toLocaleDateString();
      let todayDate = new Date().toLocaleDateString();
      if (fullDate === todayDate) {
        return `${new Date(date).toLocaleTimeString()}`
      }
      else return `${fullDate} - ${new Date(date).toLocaleTimeString()}`;
    },
    checkSensorRange(val, low, high) {
      if (low === null || high === null) {
        return "black";
      } else if (val >= high || val <= low) {
        return "red";
      } else if (val >= high - 2 || val <= low + 2) {
        return "#d8d300";
      } else return "green";
    },
    routeTo(item) {
      if (item.duel) {
        this.$router.push(`/device/${item.uuid}/${item.duelId}`);
      }
      else this.$router.push(`/device/${item.uuid}`);
    }
  }
};
</script>

<style lang="scss" scoped>
tr {
  cursor: pointer;
}
</style>
<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>
          Locations
          <div class="flex-grow-1"></div>
          <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table
          v-if="locations.length"
          :headers="headers"
          :items="locations"
          :items-per-page="10"
          item-key="id"
          class="elevation-1"
          :search="search"
        >
          <template v-slot:body="{items}">
            <tbody>
              <template v-for="item in items">
                <tr @click="setSensors(item.sensors.items)" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td>{{ item.owner ? item.owner.email : "" }}</td>
                  <td>{{ item.sensors.items.length }}</td>
                  <td>{{ item.users.items.length }}</td>
                  <td>{{ item.code }}</td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-col>
      <Sensors :sensors="sensors" />
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Sensors from "@/components/Sensors";
export default {
  name: "locations",
  data() {
    return {
      search: "",
      sensors: [],
      headers: [
        { text: "Name", value: "name" },
        { text: "Owner", value: "" },
        {
          text: "Sensors",
          value: "",
          sortable: false,
        },
        {
          text: "Users",
          value: "",
          sortable: false,
        },
        {
          text: "Code",
          value: "code",
          sortable: false,
        }
      ]
    };
  },
  async created() {
    if (this.isSuper || this.isDistributor) {
      await this.$store.dispatch("location/allLocations")
      this.sensors = this.locations[0].sensors.items
    }
    // else if (this.isDistributor) {
    //   this.$store.dispatch(
    //     "user/getDistributorUsers",
    //     this.userDistributorName
    //   );
    // } else {
    //   console.log("no users");
    // }
  },
  components: {
    Sensors
  },
  computed: {
    ...mapState("location", ["locations"]),
    ...mapGetters("user", [
      "isSuper",
      "isDistributor",
      "isAdmin",
      "userDistributorName"
    ])
  },
  methods: {
    setSensors(items) {
      this.sensors = items
    },
    async approveUser(id, email) {
      await this.$store.dispatch("user/approveUserRegistration", { id, email });
    },
    async disableUser(id) {
      await this.$store.dispatch("user/disableUserRegistration", id);
    },
    capitalize(word) {
      return word.slice(0, 1).toUpperCase() + word.slice(1);
    }
  }
};
</script>

<style lang="scss" scoped>
tr {
  cursor: pointer;
}
</style>